import React from 'react'
import PropTypes from 'prop-types'
// import axios from 'axios'
import Layout from '../../components/layout'
// import BlogPost from '../../components/blog/BlogPost'

// const getPost = async id => {
//   const { data } = await axios.get(
//     `${process.env.GATSBY_API_URL}/geneone/wp-json/wp/v2/posts/${id}`
//   )
//   return data
// }

// const getAuthor = async id => {
//   const { data } = await axios.get(
//     `${process.env.GATSBY_API_URL}/geneone/wp-json/wp/v2/users/${id}`
//   )
//   return data
// }

const PagePreview = ({ location, pageContext }) => {
  // const [content, setContent] = useState({
  //   title: '',
  //   date: '',
  //   content: '',
  //   excerpt: '',
  //   author: {
  //     name: '',
  //   },
  // })
  // const [isError, setIsError] = useState(false)

  // useEffect(() => {
  //   const getData = async () => {
  //     const urlParams = new URLSearchParams(window.location.search)
  //     const postId = urlParams.get('id')
  //     const post = await getPost(postId)
  //     const author = await getAuthor(post.author)
  //     setContent({
  //       title: post.title.rendered,
  //       date: new Date().toLocaleDateString('pt-BR'),
  //       content: post.content.rendered,
  //       excerpt: post.excerpt.rendered,
  //       author: {
  //         name: author.name,
  //       },
  //     })
  //   }
  //   try {
  //     getData()
  //   } catch {
  //     setIsError(true)
  //   }
  // }, [])

  return (
    <Layout location={location} pageContext={pageContext}>
      {/* {!isError ? <BlogPost post={content} relatedPosts={[]} /> : <h1>Falha ao obter preview.</h1>} */}
    </Layout>
  )
}

PagePreview.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default PagePreview
